<template lang="pug">
  .export-statuses-tooltip(:id="`target-period-${targetPeriod.$model.id}`")
    FaIcon(
      :class="{ shadow: isShadowShown }"
      icon="info-circle"
      @mouseover="handleMouseOver"
    )
    BPopover(
      :target="`target-period-${targetPeriod.$model.id}`"
      triggers="hover"
    )
      .otas
        .ota(
          v-for="statuses, otaName in statusesByOta"
          v-if="!isEmpty(statuses)"
        )
          .ota-name {{ otaName }}
          .status(
            v-for="({ status, insurance, className }) in statuses"
          )
            .insurance {{ insurance }}
            .delimeter - 
            .status-value(:class="kebabCase(className)") {{ status }}
</template>

<script>
  import { BPopover } from "bootstrap-vue"
  import { OPTIONAL, INCLUDED } from "@/config/constants"
  import { reduce, forEach, get, set, isEmpty, kebabCase } from "lodash-es"

  const INSURANCES = [OPTIONAL, INCLUDED]

  export default {
    components: {
      BPopover
    },

    props: {
      targetPeriod: Object
    },

    computed: {
      isShadowShown() {
        return this.targetPeriod.$model._statusUpdated
      },

      otas() {
        return this.$store.getters.translatedOtaList
      },

      statusesByOta({ otas, targetPeriod }) {
        return reduce(
          otas,
          (obj, { rawName, name }) => {
            obj[name] ||= []

            forEach(INSURANCES, insurance => {
              const status = get(targetPeriod, `$model.${rawName}_${insurance}_export_status`)

              if (status)
                obj[name].push({
                  insurance: this.$t(`general_settings.rule_settings.${insurance}`),
                  status: this.$t(`plan_settings.settings_by_period.tooltip.statuses.${status}`),
                  className: status
                })
            })

            return obj
          },
          {}
        )
      }
    },

    methods: {
      isEmpty,
      kebabCase,

      handleMouseOver() {
        set(this.targetPeriod, `_statusUpdated.$model`, false)
        this.$emit("update-period")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  @keyframes blink
    0%
      opacity: 1
    50%
      opacity: 0.5
    100%
      opacity: 1

  .shadow
    color: $default-purple
    filter: drop-shadow(0 0 2px $default-purple)
    animation: blink 3.0s linear infinite

  =status-label($color)
    border: 1px solid $color
    color: $color
    box-shadow: 0 0 4px -1px $color

  .otas
    max-height: 500px
    overflow-y: auto

    .ota
      border-bottom: 1px solid $default-purple-light-line
      margin-bottom: 10px
      padding-bottom: 10px

      &:first-child
        border-top: 1px solid $default-purple-light-line
        margin-top: 10px
        padding-top: 10px

      .ota-name
        color: $default-purple-light-line
        font-weight: 700
        text-align: center

      .status
        align-items: center
        display: flex
        font-size: 0.75em
        height: 30px
        justify-content: space-between

        .delimeter
          margin: 0 5px

        .status-value
          border-radius: 5px
          padding: 0 5px

          &.not-set
            +status-label($default-black)

          &.applied
            +status-label($default-purple)

          &.create-failed,
          &.update-failed,
          &.delete-failed
            +status-label($default-red)
</style>
